import { Router } from "@reach/router";
import React from "react";

import { AppRedirect, NotValid, EmptyPage } from "./views";

export default function AppRouter() {
  return (
    <Router>
      <AppRedirect path="/redir/*" />
      <NotValid path="/not-valid" />
      <EmptyPage default />
    </Router>
  );
}
